import { graphql } from "gatsby";
import Layout from "../../../../src/components/Layout";
import { BioSection, BlogSection, BookHero, QuoteSection, BookPreviewSection, InterviewSection } from "../../../../src/components/Pages/Home";
import { useSiteMetadata } from "src/hooks";
import { SEO } from "gatsby-plugin-seo";
import { MailSignUp, Section } from "../../../../src/components/UI";
import React from 'react';
export default {
  graphql,
  Layout,
  BioSection,
  BlogSection,
  BookHero,
  QuoteSection,
  BookPreviewSection,
  InterviewSection,
  useSiteMetadata,
  SEO,
  MailSignUp,
  Section,
  React
};