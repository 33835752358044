import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import Chevron from '../../img/chevron_right.svg'

const StandAloneLink = ({
  children,
  to,
  className = "",
  normalLink = false,
  target
}) => {
  if (normalLink) {
    return (
      <div className={className}>
        <a
          target={target}
          className="text-sm font-semibold uppercase tracking-wide flex items-center text-blue4"
          href={to}
        >
          <span className="pr-3 pt-px">{children}</span>
          <Chevron width="36" height="36" className="mr-2" />
        </a>
      </div>
    );
  }

  return (
    <div className={className}>
      <Link
        className="text-sm font-semibold uppercase tracking-wide flex items-center text-blue4"
        to={to}
      >
        <span className="pr-3 pt-px">{children}</span>
        <Chevron width="36" height="36" className="mr-2" />
      </Link>
    </div>
  );
};

StandAloneLink.propTypes = {
  to: PropTypes.string.isRequired
};

export default StandAloneLink;
