import React from "react";
import PropTypes from "prop-types";

const ImagePlaceholder = ({ description }) => (
  <div className="bg-black text-white w-full h-full flex items-center justify-center shadow">
    <span>{description}</span>
  </div>
);

ImagePlaceholder.propTypes = {
  description: PropTypes.string
};

ImagePlaceholder.defaultProps = {
  description: ""
};

export default ImagePlaceholder;
