import Layout from "../../../../src/components/Layout";
import { SEO } from "gatsby-plugin-seo";
import { Section, ProfileImage, BookCtaBanner } from "../../../../src/components/UI";
import { MDXProvider } from "@mdx-js/react";
import { H1, Lead, MdxComponentMap } from "../../../../src/components/Typography";
import { useSiteMetadata } from "../../../../src/hooks";
import React from 'react';
export default {
  Layout,
  SEO,
  Section,
  ProfileImage,
  BookCtaBanner,
  MDXProvider,
  H1,
  Lead,
  MdxComponentMap,
  useSiteMetadata,
  React
};