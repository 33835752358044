/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import { Link } from "gatsby";
import NavLink from "./NavLink";
import Section from "../../UI/Section";
import LogoBlueSvg from "../../../img/logo_blue2.svg";
import LogoSvg from "../../../img/logo_black.svg";

const Navbar = () => {
  return (
    <nav role="navigation" aria-label="main-navigation" className="shadow">
      <Section>
        <div className="flex justify-center content-center pt-2 pb-2 md:pb-4 flex-wrap xs:flex-no-wrap xs:justify-between items-center">
          <div className="flex justify-center sm:hidden">
            <Link to="/" title="Home">
              <span className="pb-3 xs:pb-0 block">
                <LogoBlueSvg width="40" height="40" alt="Logo" />
              </span>
            </Link>
          </div>
          <div className="w-full xs:w-auto text-center hidden sm:block">
            <Link to="/" title="Home">
              <LogoSvg width="80" height="90.47" alt="Logo" />
            </Link>
          </div>
          <div className="w-full xs:w-auto flex justify-center">
            <NavLink className="mx-3 xs:ml-0 xs:mr-8" to="/">
              Book
            </NavLink>
            <NavLink className="mx-3 xs:ml-0 xs:mr-8" to="/about">
              About
            </NavLink>
            <NavLink className="mx-3 xs:mx-0 xs:mr-2" to="/blog">
              Blog
            </NavLink>
          </div>
        </div>
      </Section>
    </nav>
  );
};

export default Navbar;
