import React from "react";
import { Link } from "gatsby";
import startCase from "lodash/startCase";
import kebabCase from "lodash/kebabCase";

import { useTags } from "gatsby-theme-blog-ml-core";
import { H3 } from "../Typography";

const TagsList = ({ activeTag }) => {
  const tags = useTags();
  const tagElements = tags.map(t => {
    const activeClass = t.name === activeTag ? "text-red-100" : "";
    return (
      <li key={t.name} className="my-3">
        <Link
          className={`underline text-gray-900 hover:text-red-100 transition ${activeClass}`}
          to={`/tags/${kebabCase(t.name)}/`}
        >
          {startCase(t.name)} ({t.count})
        </Link>
      </li>
    );
  });

  return (
    <div>
      <H3>Categories</H3>
      <ul>{tagElements}</ul>
    </div>
  );
};

export default TagsList;
