import React from "react";

const H2 = ({ children, className = "" }) => {
  return (
    <h2
      className={` 
  ${className}
  leading-tight
  border-b
  text-2xl
  font-semibold
  mb-4
  mt-6
  pb-2

`}
    >
      {children}
    </h2>
  );
};

export default H2;
