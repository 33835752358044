import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import Image from "gatsby-image";
import ProfileImage from "./ProfileImage";
import { H3, Paragraph } from "../Typography";
import StandAloneLink from "./StandAloneLink";

const FooterProfileImage = ({ className = "" }) => {
  const {
    file: {
      childImageSharp: { fluid }
    }
  } = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "howard-headshot.png" }) {
        childImageSharp {
          fluid(maxWidth: 112, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <div className={className}>
      <Image fluid={fluid} className="border border-gray-300 p-1 w-25" />
    </div>
  );
};

const AuthorSummary = ({ isFooter }) => (
  <div>
    {isFooter ? (
      <div className="flex h-full">
        <div className="mr-3 hidden xs:block">
          <FooterProfileImage />
        </div>
        <div className="flex flex-col justify-center xs:justify-between xs:w-2/3 xs:text-left text-center">
          <div className="xs:hidden flex justify-center">
            <FooterProfileImage />
          </div>
          <H3 marginBottomClass="mb-0">Howard Rachinski</H3>
          <Paragraph>
            Author of <em>PERPETUAL</em>, specializes in teaching and consulting
            on church music culture, music ministry and leadership development.
          </Paragraph>
          <div className="flex justify-center xs:justify-start">
            <StandAloneLink to="/about">Full Bio</StandAloneLink>
          </div>
        </div>
      </div>
    ) : (
      <div>
        <div className="flex items-center">
          <ProfileImage className="mb-2 mr-2 w-1/3" />
          <H3>Howard Rachinski</H3>
        </div>
        <Paragraph>
          Author of <em>PERPETUAL</em>, Howard is recognized as an enthusiastic
          speaker at churches, worship conferences and leadership conferences.
          He specializes in teaching and consulting on church music culture,
          music ministry and leadership development.
        </Paragraph>
        <StandAloneLink to="/about">Full Bio</StandAloneLink>
      </div>
    )}
  </div>
);

AuthorSummary.propTypes = {
  isFooter: PropTypes.bool
};

AuthorSummary.defaultProps = {
  isFooter: false
};

export default AuthorSummary;
