import React, { useState } from "react";
import PropTypes from "prop-types";

import addToMailchimp from "gatsby-plugin-mailchimp";
import { useField, useForm } from "react-final-form-hooks";
import TextInput from "./TextInput";
import Button from "./Button";
import CheckIcon from "../../img/check_circle_outline.svg";

const MailSignUp = ({ title, buttonCta }) => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const onSubmit = values => {
    setIsLoading(true);
    console.log(values);
    addToMailchimp(values.email)
      .then(data => {
        if (data.result === "success") {
          console.log(data);
          setSuccess(true);
          setError(false);
          setIsLoading(false);
        } else {
          console.log(data);
          setError(true);
          setIsLoading(false);
          setErrorMsg(data.msg);
        }
      })
      .catch(err => {
        console.log(err);
        setError(true);
        setIsLoading(false);
      });
  };

  const { form, handleSubmit } = useForm({
    onSubmit,
    initialValues: {
      email: ""
    }
  });
  const email = useField("email", form, val => (val ? undefined : "Required"));
  const isValid = !email.meta.touched || email.input.value;

  return (
    <div>
      <h3 className="font-semibold mb-3 text-blue4">{title}</h3>
      {success ? (
        <div className="p-4 mt-4 bg-gray-100 flex">
            <CheckIcon style={{ color: "#4fd1c5" }} className="mr-2" />
          <span className="pt-px">Thank you for subscribing!</span>
        </div>
      ) : (
        <div>
          <form
            onSubmit={e => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <TextInput
              onBlur={email.input.onBlur}
              onFocus={email.input.onFocus}
              onChange={email.input.onChange}
              label="Your Email"
              isValid={isValid}
              value={email.input.value}
              validationMessage="Please enter a valid email"
            />
            <p className="text-xs text-blue4 pt-5 italic mb-5">
              We will send you an occasional update, but will respect your
              inbox.
            </p>
            <Button type="submit" loading={isLoading}>
              {buttonCta}
            </Button>
          </form>
        </div>
      )}
      {error && (
        <div
          className="text-xs text-red-100 tracking-wider pt-4"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: errorMsg }}
        />
      )}
    </div>
  );
};

MailSignUp.propTypes = {
  title: PropTypes.string,
  buttonCta: PropTypes.string
};

MailSignUp.defaultProps = {
  title: "Sign Up for New Posts & Updates",
  buttonCta: "Sign Up"
};

export default MailSignUp;
