import React from "react";
import PropTypes from "prop-types";

const Divider = ({ className }) => (
  <div className={`border-b border-gray-500 my-4 ${className}`} />
);

Divider.propTypes = {
  className: PropTypes.string
};

Divider.defaultProps = {
  className: ""
};

export default Divider;
