import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const ButtonLink = ({ buttonStyle, children, to, classNames }) => {
  let buttonClasses = "";

  switch (buttonStyle) {
    case "outlined":
      buttonClasses = "bg-transparent border-2 border-gray-100 text-black";
      break;
    case "outlined-white":
      buttonClasses = "bg-transparent border-2 border-gray-100 text-white";
      break;
    default:
      buttonClasses = "bg-red-100 hover:bg-red-200 text-white hover:text-white";
      break;
  }

  return (
    <Link
      to={to}
      className={`inline-flex
      uppercase
      font-semibold
      tracking-wide
      rounded-full
      py-3
      px-8
      shadow
      transition
      ${classNames}
      ${buttonClasses}`}
    >
      {children}
    </Link>
  );
};

ButtonLink.propTypes = {
  buttonStyle: PropTypes.oneOf(["solid", "outlined", "outlined-white"]),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  to: PropTypes.string,
  classNames: PropTypes.string
};

ButtonLink.defaultProps = {
  buttonStyle: "solid",
  to: "/",
  classNames: ""
};

export default ButtonLink;
