import React from "react";
import Image from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";
import { H1, Paragraph, Lead } from "../../Typography";
import Section from "../../UI/Section";
import ButtonLink from "../../UI/ButtonLink";

const BioSection = () => {
  const {
    file: {
      childImageSharp: { fluid }
    }
  } = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "howard-bio-profile.png" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 86) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Section
      bgColor="bg-blue4"
      className="py-10 sm:py-20 text-center sm:text-left"
    >
      <div className="flex items-center flex-wrap">
        <div className="sm:w-1/2 sm:pr-4 mb-4 sm:mb-0">
          <h2 className="italic font-serif text-2xl text-gray-300 tracking-wide">
            Meet the Author
          </h2>
          <H1 colorClass="text-gray-300">Howard Rachinski</H1>
          <div className="sm:hidden mx-auto">
            <Image
              fluid={fluid}
              alt="Howard Rachinski"
              style={{ maxWidth: "25rem" }}
              className="max-w-md mx-auto mb-4"
            />
          </div>
          <Lead colorClass="text-gray-500">
            Howard Rachinski is the Founder of Christian Copyright Licensing
            International, Inc. (CCLI), and creator of the Church Copyright
            License, which helps churches comply with the copyright law.
          </Lead>
          <Paragraph colorClass="text-gray-500">
            In addition to writing <em>PERPETUAL</em>, Howard has been a
            songwriter, arranger, record producer, and music sales manager, and
            is recognized as an experienced seminar leader and contributing
            editor in music copyright law issues. His experience also extends to
            the local church arena where he was an associate pastor for seven
            years, and a music minister for five years in one of the nation’s
            largest churches, where he directed an 80-voice choir and a 30-piece
            orchestra.
          </Paragraph>
          <Paragraph colorClass="text-gray-500">
            Howard is also recognized as an enthusiastic conference speaker, and
            specializes in teaching on worship, music culture and leadership. In
            2016, Howard was inducted into the Gospel Music Hall of Fame for his
            outstanding contribution to Christian music.
          </Paragraph>
          <ButtonLink
            buttonStyle="outlined-white"
            classNames="mt-4"
            to="/about"
          >
            Full Bio
          </ButtonLink>
        </div>
        <div className="hidden sm:block sm:w-1/2 sm:flex sm:self-stretch">
          <div className="w-100 mx-auto">
            <Image fluid={fluid} alt="Howard Rachinski" />
          </div>
        </div>
      </div>
    </Section>
  );
};

export default BioSection;
