import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Image from "gatsby-image";
import { Section, StandAloneLink } from "../../UI";
import { Paragraph } from "../../Typography";

const BookPreviewSection = () => {
  const {
    file: {
      childImageSharp: { fixed }
    }
  } = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "open-book.png" }) {
        childImageSharp {
          fixed(width: 800, quality: 87) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  return (
    <div className="py-16 ">
      <Section className="text-center">
        <h2 className="italic font-serif font-light text-2xl text-blue4 mb-4">
          Have you ever asked yourself...
        </h2>
        <h3 className="font-condensed tracking-wider text-4xl mb-4 text-gray-900">
          “What is the point of my life?”
        </h3>
        <Paragraph>
          Through the maze of crises, chaos, elation, and confusion, there is a
          mystical life-purpose strand that everyone wants to believe in and
          hold on to. Everyone frantically searches for logic out of the
          illogical and everyone desperately clings to any fashion of sanity in
          and through those unexplainable life moments. Everyone.
        </Paragraph>
      </Section>
      <Section>
        <div className="flex flex-row mt-12 mb-8 md:mb-20 lg:mb-32">
          <div className="w-1/3 relative hidden md:block">
            <div className="absolute w-screen" style={{ right: 0 }}>
              <Image
                alt="Open Book"
                fixed={fixed}
                style={{
                  position: "absolute",
                  right: 0
                }}
              />
            </div>
          </div>
          <div className="md:w-2/3 md:pl-12">
            <Paragraph className="text-sm leading-loose pb-4">
              Howard Rachinski encountered and experienced those same life
              moments. His accomplishments didn’t immunize him from the pain of
              tragedy, nor did his faith exempt him from the torment of anguish.
              Instead, Howard discovered the rhythm of life-purpose. And, he
              identified 7 Life Seasons that encompass everyone’s entire life
              journey. The 4 “Calling” Seasons – Preparation, Productivity,
              Transition, and Impartation – are when God cultivates our mission
              (our vocation, our sense of life-purpose). The 3 “Character”
              Seasons – Despair, Famine, and Refreshing – are when God works on
              our internal nature (including our integrity, honor, moral fiber,
              discipline, ethics).
            </Paragraph>
            <Paragraph className="text-sm leading-loose pb-4">
              <span className="text-red-100 uppercase font-bold">
                PERPETUAL
              </span>{" "}
              interweaves the heartfelt tapestry of Howard’s personal experience
              and vulnerability with the foundational assurance of God’s raw and
              revelatory Truth.
            </Paragraph>
            <Paragraph className="text-sm leading-loose pb-0">
              <span className="text-red-100 uppercase font-bold">
                PERPETUAL
              </span>{" "}
              untangles the hopelessness of despair that suffocates everyone
              experiencing trauma. You will be enlightened, encouraged,
              inspired, challenged, and strengthened as you pursue your own
              personal journey on this side of Eternity.
            </Paragraph>
          </div>
        </div>
        <div className="flex justify-center">
          <StandAloneLink
            target="_blank"
            normalLink
            to="/Perpetual_Chapter_1.pdf"
          >
            <span className="text-center hidden sm:block">
              Download a free excerpt from the book
            </span>
            <span className="text-center hidden xs:block sm:hidden">
              Download a free excerpt
            </span>
            <span className="text-center xs:hidden">Download excerpt</span>
          </StandAloneLink>
        </div>
      </Section>
    </div>
  );
};

export default BookPreviewSection;
