import React, { useState } from "react";
import Image from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";

export default () => {
  const { front, back, placeholder } = useStaticQuery(graphql`
    query {
      placeholder: file(name: { eq: "PERPETUAL_Cover" }) {
        id
        childImageSharp {
          fluid(maxWidth: 400, quality: 1) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      front: file(name: { eq: "PERPETUAL_Cover" }) {
        id
        childImageSharp {
          fluid(maxWidth: 400, quality: 78) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      back: file(name: { eq: "PERPETUAL_COVER_back" }) {
        id
        childImageSharp {
          fluid(maxWidth: 400, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const [showingFront, setShowingFront] = useState(true);
  return (
    <div className="flex-grow">
      <div className="relative">
        <div>
          <Image
            style={{}}
            fluid={placeholder.childImageSharp.fluid}
            alt="Book Cover"
            className="opacity-0"
          />
        </div>
        <div className="absolute w-full top-0 left-0">
          <Image
            style={{}}
            fluid={front.childImageSharp.fluid}
            alt="Front of Book Cover"
            className={`shadow-book w-full transition ${
              showingFront ? "opacity-100" : "opacity-0"
            }`}
          />
        </div>
        <div className="absolute w-full top-0 left-0">
          <Image
            fluid={back.childImageSharp.fluid}
            alt="Back of Book Cover"
            className={`shadow-book w-full transition absolute top-0 left-0 ${
              showingFront ? "opacity-0" : "opacity-100"
            }`}
          />
        </div>
      </div>
      <div className="mt-2 w-full text-center z-10">
        <button
          tabIndex="0"
          type="button"
          onClick={() => setShowingFront(!showingFront)}
          className="underline text-gray-600 hover:cursor-pointer"
        >
          {showingFront ? <span>Show Back</span> : <span>Show Front</span>}
        </button>
      </div>
    </div>
  );
};
