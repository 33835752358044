import React from "react";
import { Section } from "../../UI";
import { H1, Paragraph } from "../../Typography";

export default () => {
  return (
    <Section className="bg-gray-900 py-12 text-center">
      <H1 colorClass="text-gray-300">Watch the Interview</H1>
      <Paragraph colorClass="text-gray-500">
        Howard talks about his book.
      </Paragraph>
      <div
        className="relative overflow-hidden"
        style={{ paddingTop: "56.25%" }}
      >
        <iframe
          className="absolute top-0 left-0 w-full h-full"
          width="1200"
          height="675"
          src="https://www.youtube.com/embed/8R3d3UJP2Es"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          title="Who You Say I Am - Hillsong Worship"
        />
      </div>
    </Section>
  );
};
