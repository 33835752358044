module.exports = [{
      plugin: require('/builds/starpraise-publishing/howard-rachinski-website/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://howardrachinski.com"},
    },{
      plugin: require('/builds/starpraise-publishing/howard-rachinski-website/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/builds/starpraise-publishing/howard-rachinski-website/node_modules/gatsby-remark-images","id":"3626c0e0-58d4-5046-b0e5-cb7731a052f2","name":"gatsby-remark-images","version":"3.1.28","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":["onRouteUpdate"],"ssrAPIs":["onRenderBody"]}],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-relative-images"},{"resolve":"gatsby-remark-images","options":{"maxWidth":1280}},{"resolve":"gatsby-remark-smartypants"}],"remarkPlugins":[null]},
    },{
      plugin: require('/builds/starpraise-publishing/howard-rachinski-website/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/builds/starpraise-publishing/howard-rachinski-website/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-P52STG7"},
    },{
      plugin: require('/builds/starpraise-publishing/howard-rachinski-website/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Howard Rachinski's Website","short_name":"Howard Rachinski's Website","start_url":"/","background_color":"#fff","theme_color":"#fff","display":"standalone","icon":"src/img/logo_blue2.svg"},
    },{
      plugin: require('/builds/starpraise-publishing/howard-rachinski-website/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/builds/starpraise-publishing/howard-rachinski-website/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2048},
    },{
      plugin: require('/builds/starpraise-publishing/howard-rachinski-website/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"enableIdentityWidget":false},
    },{
      plugin: require('/builds/starpraise-publishing/howard-rachinski-website/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
