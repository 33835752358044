import React from "react";

const UL = ({ children, className = "" }) => {
  return (
    <ul className={`mb-2 list-outside ml-5 list-disc ${className}`}>
      {children}
    </ul>
  );
};

export default UL;
