import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const NavLink = ({ to, children, className }) => {
  return (
    <Link
      className={`sans-serif text-blue4 font-semibold tracking-wide uppercase border-b-2 border-transparent hover:border-red-100 active:border-red-100 focus:border-red-100 transition py-2 inline-flex ${className}`}
      to={to}
      activeClassName="border-red-100"
    >
      {children}
    </Link>
  );
};

NavLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  className: PropTypes.string
};

NavLink.defaultProps = {
  className: ""
};

export default NavLink;
