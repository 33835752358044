import React from "react";
import PropTypes from "prop-types";

const Lead = ({ children, className, colorClass, marginBottomClass }) => (
  <p
    className={`text-lg ${marginBottomClass} tracking-wide ${className} ${colorClass}`}
  >
    {children}
  </p>
);

Lead.propTypes = {
  className: PropTypes.string,
  colorClass: PropTypes.string,
  marginBottomClass: PropTypes.string
};

Lead.defaultProps = {
  className: "",
  colorClass: "text-blue4",
  marginBottomClass: "mb-5"
};

export default Lead;
