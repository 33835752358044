import React from "react";
import Img from "gatsby-image";

const FeaturedImage = ({ imageInfo, className = "" }) => {
  const defaultClass = "mx-n4";
  const { alt = "", childImageSharp, image } = imageInfo;
  if (!!image && !!image.childImageSharp) {
    return (
      <Img
        className={`${className} ${defaultClass}`}
        fluid={image.childImageSharp.fluid}
        alt={alt}
      />
    );
  }

  if (childImageSharp) {
    return (
      <Img
        className={`${className} ${defaultClass}`}
        fluid={childImageSharp.fluid}
        alt={alt}
      />
    );
  }

  if (!!image && typeof image === "string")
    return (
      <img className={`${className} ${defaultClass}`} src={image} alt={alt} />
    );

  return null;
};

export default FeaturedImage;
