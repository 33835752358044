import React from "react";
import Image from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";

const ProfileImage = ({ className = "" }) => {
  const {
    file: {
      childImageSharp: { fluid }
    }
  } = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "howard-headshot.png" }) {
        childImageSharp {
          fluid(maxWidth: 112, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <div className={className}>
      <Image
        fluid={fluid}
        className="border border-gray-300 p-1 w-16 sm:w-25 lg:w-16"
      />
    </div>
  );
};

export default ProfileImage;
