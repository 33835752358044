import React from "react";

import Section from "./Section";
import { H3 } from "../Typography";
import StandAloneLink from "./StandAloneLink";
import Divider from "./Divider";
import BlogPreview from "./BlogPreview";

export default ({
  heading = "From The Blog",
  showAllPostsLink = true,
  posts
}) => {
  const blogs = posts.slice(0, 2).map(p => {
    const { slug, title, date, featuredImage } = p.mdx.frontmatter;
    return (
      <div key={slug} className="pb-2 lg:even:pl-3 lg:odd:pr-3">
        <BlogPreview
          title={title}
          slug={slug}
          date={date}
          excerpt={p.mdx.excerpt}
          key={slug}
          image={featuredImage}
        />
      </div>
    );
  });

  return (
    <Section bgColor="bg-gray-100" className="py-12">
      <div className="flex justify-between items-center">
        <H3 marginBottomClass="mb-0">{heading}</H3>
        {showAllPostsLink ? (
          <StandAloneLink to="/blog">See all posts</StandAloneLink>
        ) : (
          <span />
        )}
      </div>
      <Divider />
      <div className="flex flex-wrap lg:flex-no-wrap">{blogs}</div>
    </Section>
  );
};
