import { graphql } from "gatsby";
import { Lead } from "../../../../src/components/Typography";
import { Section } from "../../../../src/components/UI";
import Layout from "../../../../src/components/Layout";
import { SEO } from "gatsby-plugin-seo";
import React from 'react';
export default {
  graphql,
  Lead,
  Section,
  Layout,
  SEO,
  React
};