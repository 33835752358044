import React from "react";
import PropTypes from "prop-types";
import startCase from "lodash/startCase";

import { usePosts } from "gatsby-theme-blog-ml-core";
import { H1 } from "../Typography";
import BlogPreview from "./BlogPreview";

const BlogFeed = ({ feedType, tag }) => {
  const posts = usePosts({ tag });

  let header = "Recent Writings";

  if (feedType === "tag") {
    header = `Writings On ${startCase(tag)}`;
  }
  let filteredPosts;

  if (feedType === "tag") {
    filteredPosts = posts.filter(p => p.mdx.frontmatter.tags.includes(tag));
  } else {
    // other is recent
    filteredPosts = posts.slice(0, 5);
  }

  const blogs = filteredPosts.map(p => {
    const { slug, date, title, featuredImage } = p.mdx.frontmatter;
    return (
      <div className="mb-6" key={slug}>
        <BlogPreview
          title={title}
          slug={slug}
          date={date}
          excerpt={p.mdx.excerpt}
          key={slug}
          image={featuredImage}
        />
      </div>
    );
  });

  return (
    <div>
      <H1>{header}</H1>
      {blogs}
    </div>
  );
};

BlogFeed.propTypes = {
  feedType: PropTypes.string,
  tag: PropTypes.string
};

BlogFeed.defaultProps = {
  feedType: "recent",
  tag: ""
};

export default BlogFeed;
