import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import { Lead, Paragraph } from "../Typography";
import StandAloneLink from "./StandAloneLink";
import PreviewCompatibleImage from "./PreviewCompatibleImage";

const BlogPreview = ({ title, date, excerpt, className, slug, image }) => {
  const hasImage = image !== null;
  const titleWidth = hasImage ? "w-full xs:w-3/4 lg:w-7/12" : "";
  return (
    <article
      className={`shadow pb-8 px-8 pt-6 bg-white flex flex-col h-full ${className}`}
    >
      <div className="flex mb-6 xs:flex-no-wrap flex-wrap items-center">
        {hasImage && (
          <div className="w-full xs:w-1/4 lg:w-5/12 xs:pb-0 pb-3 xs:mr-4 mr-2">
            <PreviewCompatibleImage
              imageInfo={{
                alt: `featured image thumbnail for post ${title}`,
                image
              }}
            />
          </div>
        )}
        <Link to={`/${slug}`} className={`${titleWidth}`}>
          <h4 className="leading-tight text-2xl xs:text-xl sm:text-2xl md:text-3xl text-blue4 mb-2">
            {title}
          </h4>
          <Lead marginBottomClass="mb-0">{date}</Lead>
        </Link>
      </div>
      <Paragraph className="mb-4 flex-grow">{excerpt}</Paragraph>
      <StandAloneLink to={`/${slug}`}>Keep reading</StandAloneLink>
    </article>
  );
};

BlogPreview.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  image: PropTypes.any
};

BlogPreview.defaultProps = {
  className: "",
  image: null
};

export default BlogPreview;
