import React from "react";
import PropTypes from "prop-types";
import css from "./TextInput.module.css";

const TextInput = ({
  onChange,
  value,
  label,
  isValid,
  validationMessage,
  onFocus,
  onBlur
}) => {
  let labelClasses = value ? css.shrink : "";
  if (!isValid) {
    labelClasses = `${labelClasses} text-red-100`;
  }
  return (
    <div className={`${css.container}`}>
      <input
        onBlur={onBlur}
        onFocus={onFocus}
        id={label}
        onChange={onChange}
        type="text"
        value={value}
        className={`shadow-inner transition outline-none w-full pt-4 h-12 pb-1 px-4 text-base border border-gray-100 bg-white focus:border-blue-300 active:bg-white ${css.input}`}
      />
      <label
        htmlFor={label}
        className={`font-semibold text-blue4 uppercase ${css.label} ${labelClasses}`}
      >
        {label}
      </label>
      {!isValid && validationMessage && (
        <span className="text-xs text-red-100 pt-2 tracking-wider">
          {validationMessage}
        </span>
      )}
    </div>
  );
};

TextInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isValid: PropTypes.bool,
  validationMessage: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func
};

TextInput.defaultProps = {
  isValid: true,
  validationMessage: "",
  onFocus: () => {},
  onBlur: () => {}
};

export default TextInput;
