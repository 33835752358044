import React from "react";

const ImageCaption = ({ name, url }) => {
  if (!name) {
    return <></>;
  }

  if (!url) {
    return <span className="text-center">{name}</span>;
  }

  return (
    <div className="mb-4 flex justify-center">
      <a
        className="text-center text-xs text-gray-700 mb-4 underline hover:text-red-100 transition"
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        title="Photo credit"
      >
        {name}
      </a>
    </div>
  );
};

export default ImageCaption;
