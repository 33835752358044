import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { H1 } from "../../Typography";
import Section from "../../UI/Section";

// const images =

const Review = ({ review, name, profile }) => {
  return (
    <div className="lg:mb-4 mb-12 lg:w-1/2 text-left lg:mx-6 flex">
      {profile && (
        <div className="block pr-4">
          <Img
            fixed={profile}
            className="rounded-full border-gray-600 border-2"
          />
        </div>
      )}
      <div>
        <p className="text-base font-serif tracking-wide italic text-blue1 lg:text-lg mb-3">
          “{review}”
        </p>
        <p className="tracking-wider text-blue1">- {name}</p>
      </div>
    </div>
  );
};

const QuoteSection = () => {
  const {
    allFile: { nodes }
  } = useStaticQuery(graphql`
    {
      allFile(filter: { sourceInstanceName: { eq: "review-images" } }) {
        nodes {
          relativePath
          childImageSharp {
            fixed(width: 80, height: 80, quality: 70) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  `);

  const marcEstes = nodes.find(n => n.relativePath === "marc-estes-profile.png")
    .childImageSharp.fixed;

  const steveMcPherson = nodes.find(
    n => n.relativePath === "steve-mcpherson.png"
  ).childImageSharp.fixed;

  const eddieDegarmo = nodes.find(
    n => n.relativePath === "eddie-degarmo-profile.png"
  ).childImageSharp.fixed;

  const geoffLorenz = nodes.find(
    n => n.relativePath === "geoff-lorenz-profile.png"
  ).childImageSharp.fixed;

  const francisAnfuso = nodes.find(
    n => n.relativePath === "francis-anfuso-profile.png"
  ).childImageSharp.fixed;

  const glenRoachelle = nodes.find(
    n => n.relativePath === "glen-roachelle-profile.png"
  ).childImageSharp.fixed;

  const markDarleneZchech = nodes.find(
    n => n.relativePath === "mark-darlene-zschech.png"
  ).childImageSharp.fixed;

  const sandraCrouch = nodes.find(n => n.relativePath === "sandra-crouch.png")
    .childImageSharp.fixed;

  return (
    <Section
      bgColor="bg-blue3"
      className="py-8 text-center border-t border-b border-gray-300"
    >
      <H1 colorClass="text-gray-300 mt-8 mb-12">Praise for PERPETUAL</H1>
      <div className="lg:flex lg:-mx-6 lg:pb-8">
        <Review
          review="PERPETUAL is a must read for any of us searching to understand
            life’s journey and our deeper relationship with God…I highly
            recommend this book."
          name="Eddie DeGarmo, Artist, Author, Gospel Music
            Hall of Fame Inductee"
          profile={eddieDegarmo}
        />

        <Review
          review="PERPETUAL is a profound road map revealing how to process enigmatic
            pain and seemingly pointless suffering while simultaneously
            uncovering life’s priceless value. A truly transforming read!"
          name="Francis Anfuso, Author and Pastor"
          profile={francisAnfuso}
        />
      </div>

      <div className="lg:flex lg:-mx-6 lg:pb-8">
        <Review
          review="You will find so much rich and practical wisdom to apply to your life and to empower you through even the most chal­lenging and stretching times…this book will be a great gift to your life as you read it with an open heart. You will finish this book much differently than you began it."
          name="Mark &amp; Darlene Zschech Senior Pastors, Worship Leader, Artist/Songwriter"
          profile={markDarleneZchech}
        />

        <Review
          review="You will not want to scan this book. It grabs you by the heart and the healing begins. It has been a great workbook for me personally. This is a must-read for every pastor, every leader, every person!"
          name="Sandra Crouch, Pastor, Artist, Grammy Award Winner"
          profile={sandraCrouch}
        />
      </div>

      <div className="lg:flex lg:-mx-6 lg:pb-8">
        <Review
          review="This book is a textbook for life...a book to be underlined,
            highlighted, and kept on nightstands, work surfaces, and in
            briefcases as a continuing reference for life’s challenges."
          name="Glen Roachelle, Pastor, Author"
          profile={glenRoachelle}
        />
        <Review
          review="PERPETUAL is the best book I have ever read that takes on all of
            the “Whys” in life’s journey and makes sense of them…"
          name="Geoff Lorenz,
            Chairman, Lorenz Corporation, Past-President, CMPA"
          profile={geoffLorenz}
        />
      </div>
      <div className="lg:flex lg:-mx-6 lg:pb-8">
        <Review
          review="From the moment my eyes engaged the opening words until the last
            closing charge, I found myself fully immersed in Howard's riveting
            stories, life-changing truths and personal application. I would
            highly recommend this book for everyone regardless of where they are
            on life’s journey."
          name="Marc Estes, Lead Pastor – Mannahouse"
          profile={marcEstes}
        />
        <Review
          review="Howard Rachinski has dug into his rich history of ministry, business
        and family life to help the reader process the seasons of life. But,
        more than that, they will better understand their purpose in God’s plan.
        I found this book personally encouraging and I do highly recommend it."
          name="Steve McPherson, CEO - Hillsong Music Publishing"
          profile={steveMcPherson}
        />
      </div>
    </Section>
  );
};

export default QuoteSection;
