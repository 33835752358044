import React from "react";
import PropTypes from "prop-types";

const H1 = ({ children, className, colorClass }) => (
  <h1
    className={`leading-tight uppercase text-3xl sm:text-4xl lg:text-5xl font-condensed tracking-wider ${colorClass} mb-3 ${className}`}
  >
    {children}
  </h1>
);

H1.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  className: PropTypes.string,
  colorClass: PropTypes.string
};

H1.defaultProps = {
  className: "",
  colorClass: "text-blue4"
};

export default H1;
