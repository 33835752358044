// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-theme-blog-ml-core-src-templates-post-page-js": () => import("/builds/starpraise-publishing/howard-rachinski-website/node_modules/gatsby-theme-blog-ml-core/src/templates/post-page.js" /* webpackChunkName: "component---node-modules-gatsby-theme-blog-ml-core-src-templates-post-page-js" */),
  "component---node-modules-gatsby-theme-blog-ml-core-src-templates-tag-page-js": () => import("/builds/starpraise-publishing/howard-rachinski-website/node_modules/gatsby-theme-blog-ml-core/src/templates/tag-page.js" /* webpackChunkName: "component---node-modules-gatsby-theme-blog-ml-core-src-templates-tag-page-js" */),
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/builds/starpraise-publishing/howard-rachinski-website/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-blog-index-js": () => import("/builds/starpraise-publishing/howard-rachinski-website/src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-404-js": () => import("/builds/starpraise-publishing/howard-rachinski-website/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-mdx": () => import("/builds/starpraise-publishing/howard-rachinski-website/src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-privacy-policy-index-mdx": () => import("/builds/starpraise-publishing/howard-rachinski-website/src/pages/privacy-policy/index.mdx" /* webpackChunkName: "component---src-pages-privacy-policy-index-mdx" */),
  "component---src-pages-about-index-mdx": () => import("/builds/starpraise-publishing/howard-rachinski-website/src/pages/about/index.mdx" /* webpackChunkName: "component---src-pages-about-index-mdx" */),
  "component---src-pages-terms-of-use-index-mdx": () => import("/builds/starpraise-publishing/howard-rachinski-website/src/pages/terms-of-use/index.mdx" /* webpackChunkName: "component---src-pages-terms-of-use-index-mdx" */)
}

