import React from "react";
import Button from "./Button";
import Section from "./Section";
import { useGlobalContent } from "../../hooks";

export default () => {
  const { buyBookUrl } = useGlobalContent();

  return (
    <Section className="bg-gray-300 py-4">
      <div className="text-center xs:text-left flex flex-wrap xs:flex-no-wrap items-center justify-between">
        <p className="sm:mb-0 mb-2 sm:text-lg lg:text-xl font-light flex-shrink">
          Howard&apos;s new book, <span className="font-bold">PERPETUAL</span>,
          is now available at Amazon.
        </p>
        <div className="xs:flex-shrink-0 flex xs:justify-end justify-center ml-4 flex-grow">
          <Button classNames="hidden sm:inline-block mr-0" href={buyBookUrl}>
            Buy It Now
          </Button>
          <Button classNames="sm:hidden" small href={buyBookUrl}>
            Buy It Now
          </Button>
        </div>
      </div>
    </Section>
  );
};
