import React from "react";
import PropTypes from "prop-types";

const Section = ({ children, bgColor, className, style = null }) => {
  return (
    <section className={`w-full ${bgColor} ${className}`} style={style}>
      <div className="max-w-6xl px-4 mx-auto">{children}</div>
    </section>
  );
};

Section.propTypes = {
  bgColor: PropTypes.string,
  className: PropTypes.string
};

Section.defaultProps = {
  bgColor: "",
  className: ""
};

export default Section;
