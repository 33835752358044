import Paragraph from "./Paragraph";
import H1 from "./H1";
import H2 from "./H2";
import H3 from "./H3";
import H4 from "./H4";
import UL from "./UL";
import LI from "./LI";
import Lead from "./Lead";

// TODO: additional elements
const MdxComponentMap = {
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  p: Paragraph,
  li: LI,
  ul: UL
};

export { Paragraph, H1, H2, Lead, H3, H4, UL, LI, MdxComponentMap };
