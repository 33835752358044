import React from "react";
import PropTypes from "prop-types";

const H3 = ({ children, className, colorClass, marginBottomClass }) => (
  <h3
    className={`text-lg tracking-wide font-sans text-black font-semibold uppercase ${colorClass} ${marginBottomClass} ${className}`}
  >
    {children}
  </h3>
);

H3.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  className: PropTypes.string,
  colorClass: PropTypes.string,
  marginBottomClass: PropTypes.string
};

H3.defaultProps = {
  className: "",
  colorClass: "text-gray-900",
  marginBottomClass: "mb-3"
};

export default H3;
