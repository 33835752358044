import React from "react";
import { MDXProvider } from "@mdx-js/react";

import Footer from "./Footer";
import Navbar from "./Navbar";
import { MdxComponentMap } from "../Typography";

const TemplateWrapper = ({ children }) => {
  return (
    <div className="h-full">
      <div className="flex flex-col h-full items-stretch">
        <Navbar />
        <div id="body" className="flex-grow">
          <MDXProvider components={MdxComponentMap}>{children}</MDXProvider>{" "}
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default TemplateWrapper;
