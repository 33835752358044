import React from "react";
import PropTypes from "prop-types";

const Paragraph = ({ children, colorClass, className }) => (
  <p
    className={`leading-relaxed ${colorClass} tracking-normal mb-6 ${className}`}
  >
    {children}
  </p>
);

Paragraph.propTypes = {
  colorClass: PropTypes.string,
  className: PropTypes.string
};

Paragraph.defaultProps = {
  colorClass: "text-blue4",
  className: ""
};

export default Paragraph;
