import React from "react";

import Section from "../../UI/Section";
import { Button } from "../../UI";
import { Paragraph } from "../../Typography";
import BookCover from "./BookCover";
import { useGlobalContent } from "../../../hooks";

const BookHero = () => {
  const { buyBookUrl } = useGlobalContent();
  return (
    <Section
      className="text-center sm:text-left pb-16 pt-16 bg-gray-100"
      // style={backgroundCover}
    >
      <div className="flex w-full items-center">
        <div className="hidden sm:flex flex-grow w-5/12 flex self-stretch pr-12">
          <BookCover />
        </div>
        <div className="flex-grow w-7/12">
          <h1 className="uppercase leading-tight text-3xl sm:text-4xl lg:text-5xl font-condensed tracking-wider text-blue4">
            Perpetual
          </h1>
          <span className="text-xl text-blue4">
            The Secret to Finding God in Your 7 Life Seasons
          </span>
          <span className="block text-blue4 italic mb-5">
            by Howard Rachinski
          </span>
          <div className="flex justify-center mb-5 sm:hidden max-w-sm mx-auto">
            <BookCover />
          </div>
          <Paragraph className="text-md leading-loose pb-4">
            Howard masterfully articulates the “what” of each life season and
            helps the reader understand the “why” for each emphasis. In this
            book, he equips the reader with life-tools that will enable growth
            and wellness in and through each Life Season. He states, “Seeing the
            beautiful order of seasons in life turned my chaos into clarity…You
            can’t go around what God wants you to go through.”
          </Paragraph>
          <div>
            <h3 className="mb-6 text-2xl font-light bold">
              Available At Amazon
            </h3>
            <Button href={buyBookUrl}>Buy It Now</Button>
          </div>
          {/* <MailSignUp
            title="Get notified when the book is released"
            subtext="Get notified when the book is released"
            buttonCta="Notify Me"
          /> */}
        </div>
      </div>
    </Section>
  );
};

export default BookHero;
